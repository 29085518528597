<template>
  <div>
    <div v-for="childGroup in groupChildren" v-bind:key="childGroup.group_id">
      {{ childGroup.name }}
      <div v-if="childGroup.children && childGroup.children.length !== 0">
        <SubGroupName
            :groupChildren="childGroup.children"
        >
        </SubGroupName>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubGroupName',
  props: ['groupChildren'],
};
</script>

<style scoped>

</style>
