var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.groupChildren, function(childGroup) {
      return _c("div", { key: childGroup.group_id }, [
        _vm._v(" " + _vm._s(childGroup.name) + " "),
        childGroup.children && childGroup.children.length !== 0
          ? _c(
              "div",
              [
                _c("SubGroupName", {
                  attrs: { groupChildren: childGroup.children }
                })
              ],
              1
            )
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }